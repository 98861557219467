import React, { useState, useEffect } from "react";
import axios from "axios";
import yaml from "js-yaml";
import { FaMicrophone, FaTimes } from "react-icons/fa";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import RecordRTC from "recordrtc";
import logo from "./logo.png";
import StarRating from "./StarRating";
import { FaStar } from "react-icons/fa";
import { Geolocation } from '@capacitor/geolocation';

function App() {
  const [logoRedirectUrl, setLogoRedirectUrl] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [token, setToken] = useState("");
  const [tokenIndication, setTokenIndication] = useState("");
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [rating, setRating] = useState(0);
  const [progressBar, setProgressBar] = useState(0);
  const [analyzing, setAnalyzing] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [hideSentiments, setHideSentiments] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response1 = await axios.get("/api/get_logo_redirect_url");
        setLogoRedirectUrl(response1.data.logoRedirectUrl);
        const tokenValue = new URLSearchParams(window.location.search).get(
          "token"
        );
        if (!tokenValue) {
          setAlertMessage(
            "Token is missing. Please contact tommaso.castelli@i2d.it to use InsightGPT"
          );
          setShowAlert(true);
        } else {
          setToken(tokenValue);
          const response2 = await axios.post(`/api/validate_token`, {
            token: tokenValue,
          });
          setIsTokenValid(response2.data.isTokenValid);
          if (!response2.data.isTokenValid) {
            setAlertMessage("Token is not valid");
            setShowAlert(true);
          } else {
            const response3 = await axios.post(`/api/get_token_indication`, {
              token: tokenValue,
            });
            setTokenIndication(response3.data.tokenIndication);
            await fetchFiles(tokenValue);
          }
        }
      } catch (error) {
        console.error("Error fetching initial data or config:", error);
      }
    };
    fetchInitialData();
    setProgressBar(0);
  }, []);

  useEffect(() => {
    let interval;
    const duration = 10 * 1000;
    const finalPhaseDuration = 30 * 1000;
    if (uploading || analyzing) {
      setProgressBar(0);
      interval = setInterval(() => {
        setProgressBar((prevProgress) => {
          let nextProgress;
          if (prevProgress < 80) {
            const increment = 80 / (duration / 100);
            nextProgress = prevProgress + increment;
            return nextProgress >= 80 ? 80 : nextProgress;
          }
          else if (prevProgress >= 80 && prevProgress < 99) {
            const slowIncrement = (99 - 80) / (finalPhaseDuration / 100);
            nextProgress = prevProgress + slowIncrement;
            return nextProgress >= 99 ? 99 : nextProgress;
          }
          return prevProgress;
        });
      }, 100);
    }
    if (!uploading && !analyzing) {
      setProgressBar(100);
    }
    return () => clearInterval(interval);
  }, [uploading, analyzing]);

  const generateAreaColors = (areas) => {
    const colors = [
      "#FFDDC1",
      "#FFE4E1",
      "#FFFACD",
      "#E0FFFF",
      "#D8BFD8",
      "#E6E6FA",
      "#FFF0F5",
      "#F0FFF0",
      "#F5F5DC",
      "#FAF0E6",
    ];
    const areaColors = {};
    let colorIndex = 0;

    areas.forEach((area) => {
      if (!areaColors[area]) {
        areaColors[area] = colors[colorIndex % colors.length];
        colorIndex++;
      }
    });

    return areaColors;
  };

  const sortAreasAndThemes = (themes) => {
    const themesByArea = themes.reduce((acc, theme) => {
      const area = theme.generic_area.split("/")[0];
      if (!acc[area]) {
        acc[area] = [];
      }
      acc[area].push(theme);
      return acc;
    }, {});
    const sortedAreas = Object.keys(themesByArea).sort((a, b) =>
      a.localeCompare(b)
    );
    sortedAreas.forEach((area) => {
      themesByArea[area].sort((a, b) => a.theme.localeCompare(b.theme));
    });
    return sortedAreas.flatMap((area) => themesByArea[area]);
  };

  const fetchFiles = async (tokenValue) => {
    try {
      const response = await axios.get(`/api/files?token=${tokenValue}`);
      const filesWithDisplayNames = response.data.map((file) => {
        let displayFilename = "";
        if (file.type === "text") {
          displayFilename = "Text Review";
        } else {
          displayFilename = "Recording";
        }
        return { ...file, displayFilename };
      });
      setFiles(filesWithDisplayNames);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleRecord = async () => {
    if (isRecording) {
      recorder.stopRecording(async () => {
        const blob = recorder.getBlob();
        const formData = new FormData();
        formData.append("file", blob, `recording_${Date.now()}.mp3`);
        formData.append("rating", rating);
        setUploading(true);
        try {
          const response = await axios.post(
            `/api/upload?token=${token}&type=recording`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          fetchFiles(token);
          const successMessage = response.data.message;
          setSuccessMessage(successMessage);
          setShowSuccess(true);
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            setAlertMessage(error.response.data.error);
          } else {
            setAlertMessage("An unexpected error occurred");
          }
          setShowAlert(true);
          console.error("Error recording review:", error.response.data);
        } finally {
          setUploading(false);
        }
        setIsRecording(false);
      });
    } else {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const newRecorder = new RecordRTC(stream, {
        type: "audio",
        mimeType: "audio/mp3",
      });
      setRecorder(newRecorder);
      newRecorder.startRecording();
      setIsRecording(true);
    }
  };

  const handleAnalyzeText = async () => {
    if (!textInput) {
      setAlertMessage("Text is missing");
      setShowAlert(true);
      return;
    }
    setAnalyzing(true);
    try {
      setAnalyzing(true);
      await axios.post(`/api/analyze?token=${token}`, {
        text: textInput,
        rating: rating,
      });
      fetchFiles(token);
      await axios.post("/api/send_to_insightgpt", {
        token: token,
        text: textInput,
        rating: rating,
      });
      setSuccessMessage(
        "Review was processed successfully and it was sent to InsightGPT!"
      );
      setShowSuccess(true);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setAlertMessage(error.response.data.error);
      } else {
        setAlertMessage("An unexpected error occurred");
      }
      setShowAlert(true);
      console.error("Error analyzing text:", error.response.data);
    } finally {
      setAnalyzing(false);
    }
  };

  const handleFileDelete = async (fileId) => {
    await axios.delete(`/api/delete/${fileId}?token=${token}`);
    fetchFiles(token);
  };

  const renderTags = (tags, sentiment) => {
    let color;
    switch (sentiment) {
      case "Positive":
        color = "lightgreen";
        break;
      case "Negative":
        color = "lightcoral";
        break;
      case "Neutral":
        color = "lightgray";
        break;
      default:
        color = "transparent";
    }
    if (tags === null) {
      return <span></span>;
    }
    return tags.map((tag, index) => (
      <span key={index} className="tag" style={{ backgroundColor: color }}>
        {tag}
      </span>
    ));
  };

  const toggleAreaHighlight = (area) => {
    if (selectedAreas.includes(area)) {
      setSelectedAreas(
        selectedAreas.filter((selectedArea) => selectedArea !== area)
      );
    } else {
      setSelectedAreas([...selectedAreas, area]);
    }
  };

  const getHighlightedText = (text, insightsYaml) => {
    let insights = [];
    try {
      insights = yaml.load(insightsYaml);
    } catch (e) {
      console.error(e);
    }
    const themes = insights.reviews
      ? insights.reviews.flatMap((review) => review.themes || [])
      : [];
    const sortedThemes = sortAreasAndThemes(themes);
    let highlightedText = text;
    sortedThemes.forEach((theme) => {
      if (theme.portions) {
        theme.portions.forEach((portion) => {
          const portionRegex = portion.portion
            .split("")
            .map((char) => {
              return char.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
            })
            .join("\\s*");
          const regex = new RegExp(`(${portionRegex})`, "gs");
          const areaColor = selectedAreas.includes(
            theme.generic_area.split("/")[0]
          )
            ? areaColors[theme.generic_area.split("/")[0]]
            : "transparent";
          highlightedText = highlightedText.replace(
            regex,
            `<span style="background-color: ${areaColor}; border-bottom: ${
              hideSentiments
                ? "none"
                : portion.sentiment === "Positive"
                ? "3px solid green"
                : portion.sentiment === "Negative"
                ? "3px solid red"
                : "3px solid gray"
            };">$1</span>`
          );
        });
      }
    });

    return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
  };

  const getUniqueAreas = (insightsYaml) => {
    let insights = [];
    try {
      insights = yaml.load(insightsYaml);
    } catch (e) {
      console.error(e);
    }

    const themes = insights.reviews
      ? insights.reviews.flatMap((review) => review.themes || [])
      : [];
    const uniqueAreas = [
      ...new Set(themes.map((theme) => theme.generic_area.split("/")[0])),
    ];
    return uniqueAreas;
  };

  const clearAllSelections = () => {
    setSelectedAreas([]);
  };

  const toggleHideSentiments = () => {
    setHideSentiments(!hideSentiments);
  };

  const uniqueAreas = [
    ...new Set(files.flatMap((file) => getUniqueAreas(file.insights))),
  ];
  const areaColors = generateAreaColors(uniqueAreas);

  return (
    <div className="container">
      <div className="header">
        <h2 class="insight-feed-header">
          <i>Insight</i>Feed by{" "}
          <a href={logoRedirectUrl} target="_blank" rel="noopener noreferrer">
            <img className="responsive-logo" src={logo} alt="Logo" />
          </a>
        </h2>
        <br />
        <br />
        {showAlert && (
          <div className="alert alert-warning" role="alert">
            {alertMessage}
          </div>
        )}
        {showSuccess && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}
        {isTokenValid && (
          <div>
            <h4>{tokenIndication}</h4>
            <div className="textarea-button-container">
              <button
                className={`custom-record-button ${
                  isRecording ? "recording" : ""
                }`}
                onClick={handleRecord}
                disabled={uploading || analyzing}
              >
                {isRecording ? "Stop Recording" : "Start Recording"}{" "}
                <FaMicrophone />
              </button>
              <textarea
                className="text-input"
                placeholder="Write your review"
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
              />
              <StarRating rating={rating} setRating={setRating} />
            </div>
            <button
              className="analyze-button"
              onClick={handleAnalyzeText}
              disabled={!textInput || analyzing}
            >
              Analyze Written Review
            </button>
            {(uploading || analyzing) && (
              <div className="container">
                <div className="progress-bar">
                  <div
                    className="progress"
                    style={{
                      width: `${progressBar}%`,
                      backgroundColor: "#1da1f2",
                    }}
                  >
                    {Math.round(progressBar)}%
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <ul className="file-list">
        {files.map((file) => (
          <li key={file.id} className="file-item">
            <button
              onClick={() => handleFileDelete(file.id)}
              className="delete-button"
            >
              &times;
            </button>
            <div className="file-info">
              <h5 className="filename">
                {file.displayFilename}&nbsp;
                {[1, 2, 3, 4, 5].map((star) => (
                  <FaStar
                    key={star}
                    size={20}
                    color={star <= file.rating ? "#FFD700" : "#e4e5e9"}
                  />
                ))}
              </h5>
              <small className="date">
                {new Date(file.created_at).toLocaleString()}
              </small>
              <div className="text">
                {getHighlightedText(file.text, file.insights)}
              </div>
              <button
                className="hide-sentiments"
                onClick={toggleHideSentiments}
                style={{
                  backgroundColor: "#ddd",
                  borderRadius: "15px",
                  margin: "5px",
                  padding: "5px 10px",
                  cursor: "pointer",
                  border: "1px solid gray",
                  width: "150px",
                }}
              >
                {hideSentiments ? "Show Perception" : "Hide Perception"}
              </button>
              <div className="area-tags">
                {getUniqueAreas(file.insights).map((area) => (
                  <button
                    key={area}
                    className={`area-tag ${
                      selectedAreas.includes(area) ? "selected" : ""
                    }`}
                    style={{
                      backgroundColor: areaColors[area],
                      borderRadius: "15px",
                      margin: "5px",
                      padding: "5px 10px",
                      cursor: "pointer",
                      transform: selectedAreas.includes(area)
                        ? "translateY(-5px)"
                        : "translateY(0)",
                      transition: "transform 0.2s",
                      border: "1px solid gray",
                    }}
                    onClick={() => toggleAreaHighlight(area)}
                  >
                    {area}
                  </button>
                ))}
                {selectedAreas.length > 0 && (
                  <button
                    className="clear-selection"
                    onClick={clearAllSelections}
                    style={{
                      backgroundColor: "#ddd",
                      borderRadius: "15px",
                      margin: "5px",
                      padding: "5px 10px",
                      cursor: "pointer",
                      border: "1px solid gray",
                    }}
                  >
                    Clear All <FaTimes />
                  </button>
                )}
              </div>
              <div className="insights">
                {yaml.load(file.insights)?.reviews?.map((review, index) => (
                  <div key={index} className="review">
                    {sortAreasAndThemes(review.themes || []).map(
                      (theme, themeIndex) => (
                        <div
                          key={themeIndex}
                          className="theme"
                          style={{
                            backgroundColor:
                              areaColors[theme.generic_area.split("/")[0]],
                            borderColor:
                              areaColors[theme.generic_area.split("/")[0]],
                            borderWidth: "2px",
                            borderStyle: "solid",
                          }}
                        >
                          <div className="theme-header">
                            <h6>{theme.theme}</h6>
                            <span className="theme-area">
                              {theme.generic_area}
                            </span>
                          </div>
                          <div className="theme-portions">
                            {theme.portions?.map((portion, portionIndex) => (
                              <div key={portionIndex} className="portion">
                                <p>{portion.portion}</p>
                                <div className="tags">
                                  {renderTags(
                                    portion.positive_tags,
                                    "Positive"
                                  )}
                                  {renderTags(
                                    portion.negative_tags,
                                    "Negative"
                                  )}
                                  {renderTags(portion.neutral_tags, "Neutral")}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ))}
              </div>
            </div>
            {false && file.type !== "text" && (
              <audio
                controls
                src={`/audio_files/${file.token}/${file.filename}`}
                className="audio-player"
              ></audio>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
